<template>
  <div>
    <div v-if="dataLoaded && show404 === false">
      <section
        class="usa-hero bg-primary-dark"
        style="background-image: none"
      >
        <div class="grid-container padding-bottom-2">
          <div
            class="usa-hero__callout padding-2"
            style="background-color: transparent; max-width: 100%"
          >
            <h1 class="font-serif-xl tablet:font-serif-3xl">
              {{ pageData.fields.pageHeading }}
            </h1>
            <div class="usa-hero__body">
              <rich-text-renderer
                :data="pageData.fields.pageMainDescription"
              ></rich-text-renderer>
            </div>
          </div>
        </div>
      </section>
      <div :key="i" v-for="(ref, i) in pageData.fields.stepPageSectionRefs">
        <div class="row" :class="{ even: i % 2 == 0, odd: i % 2 !== 0 }">
          <document-renderer :documentId="ref.sys.id" :additionaldata="{ratio: ratio }"></document-renderer>
        </div>
      </div>
    </div>
    <PageNotFound v-if="dataLoaded && show404 === true" />
    <div :hidden="!showModal">
      <div v-if="this.pageData.fields.modalPopupRefs !== null" >
        <document-renderer :key="i" v-for="(gp, i) of pageData.fields.modalPopupRefs" :documentId="gp.sys.id" :additionaldata="{
            calculator: calculator,
            showModal: showModal,
            ratio: ratio 
          }">
        </document-renderer>
      </div>
    </div>
  </div>
</template>

<script>
import { getDataByContentType,getExchangeRate } from "@/store/api";
import PageNotFound from "@/components/PageNotFound";
import {
  getCountryResourceGroup,
  getSystemGlobalResources,
  getUSTDLiveUrlStatus
} from "@/store/api";

import { urlSlug} from "@/helpers/url";
function outsideMenuClickHandler(event) {
  const isButtonClicked = event.target.closest(".quick-notice"); 
  const isMegaMenuClicked = event.target.closest("#group-fees-dialog"); 
  if (!isButtonClicked && !isMegaMenuClicked) {
    this.permanentHide = 'permanentHide';
  }
}
function loadData() {
  this.dataLoaded = false;
  getDataByContentType("stepPageTemplate", 'homepage', this.$route.params.countrycode, this.$route.params.langcode).then((pageData) => {
    this.dataLoaded = true;
    if (!pageData) {
      this.show404 = true; 
    }
    
    // Avnish, 04-Sept-2023, Requirement - check and show the country is live or not.
    getCountryResourceGroup(this.$route.params.countrycode).then((res) => {
      if(res?.fields?.resJson) {
        let globalResourceNotLiveArr = res.fields.resJson['non-live-countries'];
        // globalResourceNotLiveArr = [{"country": "de","language": "en","isNonLive": "true" }];
        console.log("globalResourceNotLiveArr:",globalResourceNotLiveArr);
        console.log("Host :",this.host);
        console.log("route Country and Lang Code:: ", this.$route.params.countrycode, this.$route.params.langcode)
        if((globalResourceNotLiveArr) && (this.host !='cgistaging.scriptbytes.co.in')){
          let filterCurrentCountryObj = globalResourceNotLiveArr.filter(el => el.country == this.$route.params.countrycode.trim() && ((el.language == this.$route.params.langcode.trim() && (el.isNonLive == 'true' || el.isNonLive == true)) || (!el.language && (el.isNonLive == 'true' || el.isNonLive == true))));
          console.log("filterCurrentCountryObj:", filterCurrentCountryObj);
          if(filterCurrentCountryObj.length > 0) {
            if(filterCurrentCountryObj[0]['isNonLive'] == "true" && this.$route.query.accesstoken && this.$route.query.accesstoken == filterCurrentCountryObj[0]['accesstoken']) {
              console.log("country level token wise accesible for non live country.", this.$route.query.accesstoken);
            }
            else {
              getSystemGlobalResources().then((globalResponse) => {
                let globalAccessToken = globalResponse?.fields?.resJson?.accesstoken;
                // globalAccessToken = "20ca9a431341756398632ada6b761820";
                if(this.$route.query.accesstoken && globalAccessToken == this.$route.query.accesstoken) {
                  console.log("global level token wise accesible for non live country.", this.$route.query.accesstoken);
                }
                else {
                  getUSTDLiveUrlStatus(window.location.origin).then((liveUrlStatus) => {
                    console.log("liveUrlStatus:", liveUrlStatus);
                    if(!liveUrlStatus) {
                      this.show404 = true;
                    }
                  });
                }
              });
            }
          }
        }
      }
      this.pageData = pageData;
      this.setPageTitle(this.pageData.fields.pageTitle || "USTravelDocs");
    });
  });
}

export default {
  name: "home-page-template",
  components: { PageNotFound },
  data() {
    return {
      dataLoaded: false,
      pageData: {},
      currentUrl: location.toString(),
      host: location.host,
      show404: false,      
      showModalClass: "",
      showModal: true,
      calculator: this,
      quickNotice: false,
      ratio: "",
      pageData:{
            fields: {
              modalPopupRefs: null
            }
        }
    };
  },
  created() {
    loadData.call(this);
    this.$on("close-modal", () => {
      this.showModal = false;
      this.quickNotice = true;
    });
    if(location.search.includes('nodialog')){
      this.showModal = false;
      this.quickNotice = false;
    }
    getExchangeRate(this.$route.params.countrycode).then(
            (ratio) => {
              this.ratio = ratio;
            });
  },
   mounted() {
      this.$root.$on("modal-popup:show", () =>{
      this.showModal = true;
      this.quickNotice = false;
    });
  },
  methods: {
    outsideMenuClickHandler: outsideMenuClickHandler,
    showQuickNotice() {
      this.quickNotice = false;
      this.showModal = true;
      this.$nextTick(() => {
        this.$el.querySelector('.usa-dialog__close').focus();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.page-header {
  padding: 2rem 0 3rem 0;
  background-color: #205493;

  ::deep .content {
    max-width: 40rem;
    padding: 0 2rem;
    h1 {
      font-family: Helvetica, Merriweather_Web, Georgia, Cambria, Times New Roman, Times,
        serif;
      color: white;
      font-size: 2.9rem;
    }
    p {
      font-family: Helvetica, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
      color: white;
      line-height: 1.5;
    }
  }
}
::v-deep .usa-hero__body {
  p {
    margin: 0 0 15px;
  }
  ul {
    li {
      p {
        margin: 0 0 5px;
      }
    }
  }
  a {
    color: #fff;
  }
}

::v-deep ul {
    li {
      p {
        margin: 0 0 5px;
      }
    }
  }
.showModal {
  display: block !important;
}
</style>